
import axios from "axios";
import React from "react";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { Block } from 'notiflix/build/notiflix-block-aio';

// export const IMAGE_URL = 'http://localhost:5000/static/';
// export const API_URL = 'http://localhost:5000';
// export const SITE_LINK = 'http://localhost:3000';

// export const IMAGE_URL = 'https://staging.paytez.io/static/';
// export const API_URL = 'https://staging.paytez.io';
// export const SITE_LINK = 'https://staging.paytez.io';

export const IMAGE_URL = 'https://app.paytez.io/static/';
export const API_URL = 'https://app.paytez.io';
export const SITE_LINK = 'https://app.paytez.io';

export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')

//APOTHEM
export const CHAIN_ID = "51";
export const NETWORK_NAME = "APOTHEM";
export const EXPLORER_URL = "https://explorer.apothem.network/txs/"
export const EXPLORER_WALLET_URL = "https://explorer.apothem.network/address/"
export const PLUGIN_DEFI_CONTRACT = "0xb3db178db835b4dfcb4149b2161644058393267d";
export const PAYABLE_FARM_CONTRACT = "0x39b83C3f4F636E24721AabB50a0D31851b44dFcb"
export const RECEIVE_FARM_CONTRACT = "0x22c6124F5bb1FDdC34cE1e863133Df1582c9980B"

//MAINNET
// export const CHAIN_ID = "50";
// export const NETWORK_NAME = "MAINNET";
// export const EXPLORER_URL = "https://explorer.xinfin.network/txs/"
// export const EXPLORER_WALLET_URL = "https://explorer.xinfin.network/address/"
// export const PLUGIN_DEFI_CONTRACT = "0xe2b78d27f0c07f7f7630971b297bbf0d121b92a7";
// export const PAYABLE_FARM_CONTRACT = "0x1E9a80e4c04232d7a7cB21e91Aa24C5ee3adC692"
// export const RECEIVE_FARM_CONTRACT = "0xB6B943a44Dc9F87439A6CaCd0Ef2467DB96Ff9f0"

export const AVAILABLE_NETWORK = [
  { networkID: 1, networkName: "Ethereum", symbol: "ETH", mainnet: true, icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },
  { networkID: 5, networkName: "Goerli", symbol: "ETH", mainnet: false, icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },
  { networkID: 11155111, networkName: "Sepolia", symbol: "ETH", mainnet: false, icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },



  { networkID: 50, networkName: "XDC Network", symbol: "XDC", icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png", mainnet: true },
  { networkID: 51, networkName: "XDC Apothem", symbol: "XDC", icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png" },

  { networkID: 137, networkName: "Polygon", symbol: "MATIC", icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Polygon_Blockchain_Matic_Logo.svg/880px-Polygon_Blockchain_Matic_Logo.svg.png", mainnet: true },
  { networkID: 80002, networkName: "Polygon Amoy", symbol: "MATIC", icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Polygon_Blockchain_Matic_Logo.svg/880px-Polygon_Blockchain_Matic_Logo.svg.png", mainnet: false },


  { networkID: 39, networkName: "U2U Mainnet", symbol: "U2U", icon: "https://u2u-aws-hackathon.uniultra.xyz/logo.svg", mainnet: true },
  { networkID: 2484, networkName: "Unicorn Testnet", symbol: "U2U", icon: "https://u2u-aws-hackathon.uniultra.xyz/logo.svg", mainnet: false },

  // { networkID: 245022934, networkName: "Neon", symbol: "NEON", icon: "https://icons.llamao.fi/icons/chains/rsz_neon.jpg", mainnet: true },

  // { networkID: 245022926, networkName: "Neon DevNet", symbol: "NEON", icon: "https://icons.llamao.fi/icons/chains/rsz_neon.jpg", mainnet: false },

  //SEI BLOCKCHAIN 
  { networkID: 1329, networkName: "Sei Mainnet", symbol: "Sei", icon: "https://s3.coinmarketcap.com/static-gravity/image/992744cfbd5e40f5920018ee7a830b98.png", mainnet: true },
  { networkID: 1328, networkName: "Sei Testnet", symbol: "Sei", icon: "https://s3.coinmarketcap.com/static-gravity/image/992744cfbd5e40f5920018ee7a830b98.png", mainnet: false },
]

export const AVAILABLE_ASSETS = [
  { label: "ETH", value: "ETH", networkID: 1, type: "coin", ids: "ethereum", icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },
  { label: "ETH", value: "ETH", networkID: 5, type: "coin", ids: "ethereum", icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },
  { label: "ETH", value: "ETH", networkID: 11155111, type: "coin", ids: "ethereum", icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },



  { label: "MATIC", value: "MATIC", networkID: 137, type: "coin", ids: "matic-network", icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Polygon_Blockchain_Matic_Logo.svg/880px-Polygon_Blockchain_Matic_Logo.svg.png" },
  { label: "MATIC", value: "MATIC", networkID: 80002, type: "coin", ids: "matic-network", icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Polygon_Blockchain_Matic_Logo.svg/880px-Polygon_Blockchain_Matic_Logo.svg.png" },


  { label: "XDC", value: "XDC", name: "XDC Network", networkID: 50, type: "coin", ids: "xdce-crowd-sale", icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png" },
  { label: "XDC", value: "XDC", name: "XDC Network Apothem", networkID: 51, type: "coin", ids: "xdce-crowd-sale", icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png" },

  { label: "PLI", value: "PLI", name: "Plugin", networkID: 51, type: "token", ids: "plugin", contractAddress: "0xC110A174b3F0E1681Ef6f8eCFd136c2b992e6D12", icon: "https://avatars.githubusercontent.com/u/87472364?s=280&v=4" },
  { label: "CGO", value: "CGO", name: "Comtechgold", networkID: 51, type: "token", ids: "comtech-gold", contractAddress: "0xb3db178db835b4dfcb4149b2161644058393267d", icon: "https://www.coinlore.com/img/comtech-gold.png" },
  { label: "LBT", value: "LBT", name: "Lawblocks", networkID: 51, type: "token", ids: "law-blocks", contractAddress: "0xb3db178db835b4dfcb4149b2161644058393267d", icon: "https://s2.coinmarketcap.com/static/img/coins/200x200/20964.png" },

  { label: "NEON", value: "NEON", name: "Neon", networkID: 245022934, type: "coin", ids: "neon", icon: "https://icons.llamao.fi/icons/chains/rsz_neon.jpg" },
  { label: "NEON", value: "NEON", name: "Neon", networkID: 245022926, type: "coin", ids: "neon", icon: "https://icons.llamao.fi/icons/chains/rsz_neon.jpg" },


  { label: "U2U", value: "U2U", networkID: 39, type: "coin", ids: "unicorn-ultra", icon: "https://u2u-aws-hackathon.uniultra.xyz/logo.svg" },
  { label: "U2U", value: "U2U", networkID: 2484, type: "coin", ids: "unicorn-ultra", icon: "https://u2u-aws-hackathon.uniultra.xyz/logo.svg" },


  { label: "Sei", value: "Sei", networkID: 1329, type: "coin", ids: "sei-network", icon: "https://s3.coinmarketcap.com/static-gravity/image/992744cfbd5e40f5920018ee7a830b98.png" },
  { label: "Sei", value: "Sei", networkID: 1328, type: "coin", ids: "sei-network", icon: "https://s3.coinmarketcap.com/static-gravity/image/992744cfbd5e40f5920018ee7a830b98.png" },

]

export const addressRegex = /^0x[a-fA-F0-9]{40}$/;


export const CURRENCY_LIST = [
  "USD", "AED", "ARS", "AUD", "BDT", "BHD", "BMD", "BRL", "CAD", "CHF", "CLP",
  "CNY", "CZK", "DKK", "EUR", "GBP", "HKD", "HUF", "IDR", "ILS", "INR", "JPY",
  "KRW", "KWD", "LKR", "MMK", "MXN", "MYR", "NGN", "NOK", "NZD", "PHP", "PKR",
  "PLN", "RUB", "SAR", "SEK", "SGD", "THB", "TRY", "TWD", "UAH", "VEF", "VND",
  "ZAR"
]






export const XDC_TOKEN_ADDRESS = ""
export const POLY_TOKEN_ADDRESS = ""

export const EXPLORER_TXN_URL_LIST = {
  "ETH": "https://etherscan.io/tx",
  "WBTC": "https://etherscan.io/tx",
  "USDT": "https://etherscan.io/tx",
  "XDC": "https://explorer.xinfin.network/txs",
  "MATIC": "https://polygonscan.com/tx",

  "1": "https://etherscan.io/tx/",
  "5": "https://goerli.etherscan.io/tx/",
  "11155111": "https://sepolia.etherscan.io/tx/",
  "50": "https://explorer.xinfin.network/txs/",
  "51": "https://explorer.apothem.network/txs/",
  "137": "https://polygonscan.com/tx/",
  "80002": "https://www.oklink.com/amoy/tx/",
  "245022934": "https://neonscan.org/tx/",
  "245022926": "https://devnet.neonscan.org/tx/",
  "39": "https://u2uscan.xyz/tx/",
  "2484": "https://testnet.u2uscan.xyz/tx/",
  "1329": "https://seitrace.com/tx/",
  "1328": "https://seitrace.com/tx/"

}

export const EXPLORER_URL_LIST = {
  "ETH": "https://etherscan.io",
  "WBTC": "https://etherscan.io",
  "USDT": "https://etherscan.io",
  "XDC": "https://explorer.xinfin.network",
  "MATIC": "https://polygonscan.com",

  "1": "https://etherscan.io",
  "5": "https://goerli.etherscan.io",
  "11155111": "https://sepolia.etherscan.io",
  "50": "https://explorer.xinfin.network",
  "51": "https://explorer.apothem.network",
  "137": "https://polygonscan.com",
  "80002": "https://www.oklink.com/amoy",
  "245022934": "https://neonscan.org",
  "245022926": "https://devnet.neonscan.org",
  "39": "https://u2uscan.xyz",
  "2484": "https://testnet.u2uscan.xyz",
  "1329": "https://seitrace.com",
  "1328": "https://seitrace.com"

}

export const WALLET_URL_LIST = {
  "ETH": "https://etherscan.io/address",
  "WBTC": "https://etherscan.io/address",
  "USDT": "https://etherscan.io/address",
  "XDC": "https://explorer.xinfin.network/address",
  "MATIC": "https://polygonscan.com/address",

  "1": "https://etherscan.io/address",
  "5": "https://goerli.etherscan.io/address",
  "11155111": "https://sepolia.etherscan.io/address",
  "50": "https://explorer.xinfin.network/address",
  "51": "https://explorer.apothem.network/address",
  "137": "https://polygonscan.com/address",
  "80002": "https://www.oklink.com/amoy/address",
  "245022934": "https://neonscan.org/address",
  "245022926": "https://devnet.neonscan.org/address",
  "39": "https://u2uscan.xyz/address",
  "2484": "https://testnet.u2uscan.xyz/address",
  "1329": "https://seitrace.com/address",
  "1328": "https://seitrace.com/address"


}

export const TXN_API_URL_LIST = {
  "1": (walletAddress) =>
    `https://api.etherscan.io/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=${"R3U3XETNPP6CYPJ1BM76WC9VUCWQWZ99HX"}`,
  "5": (walletAddress) =>
    `https://api-goerli.etherscan.io/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=${"R3U3XETNPP6CYPJ1BM76WC9VUCWQWZ99HX"}`,
  "11155111": (walletAddress) =>
    `https://api-sepolia.etherscan.io/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=${"R3U3XETNPP6CYPJ1BM76WC9VUCWQWZ99HX"}`,
  "51": (walletAddress) =>
    `https://api-apothem.xdcscan.io/addresses/${walletAddress}/transactions?limit=10&page=1`,
  "50": (walletAddress) =>
    `https://explorer.xinfin.network/api/txs/listByAccount/${walletAddress}?page=1&limit=10&tx_type=all`,
  "137": (walletAddress) =>
    `https://api.polygonscan.com/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=${"4VGZXR329J9XNVXR7VIBD44CUNPEQ22W5H"}`,
  "80002": (walletAddress) =>
    `https://www.oklink.com/api/v5/explorer/address/transaction-list?chainShortName=AMOY_TESTNET&limit=10&address=${walletAddress}&headerKey=Ok-Access-Key&headerValue=8a447437-c3d5-425b-920f-9f2457f09a6e`,
  "245022934": (walletAddress) => `https://api.neonscan.org/address/txs?address=${walletAddress}&offset=0&limit=10`,
  "245022926": (walletAddress) => `https://beta-devnet-api.neonscan.org/address/txs?address=${walletAddress}&offset=0&limit=10`,
  "39": (walletAddress) => `https://u2uscan.xyz/api/v2/addresses/${walletAddress}/transactions`,
  "2484": (walletAddress) => `https://testnet.u2uscan.xyz/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc`,
  "1329": (walletAddress) => `https://seitrace.com/pacific-1/gateway/api/v1/addresses/${walletAddress}/transactions?type=EVM`,
  "1328": (walletAddress) => `https://seitrace.com/atlantic-2/gateway/api/v1/addresses/${walletAddress}/transactions?type=EVM`,

}

export const TXN_RESPONSE_URL_LIST = {
  "1": (response) => {
    if (response && response.result) {
      return response.result
    } else {
      return []
    }
  },
  "5": (response) => {
    if (response && response.result) {
      return response.result
    } else {
      return []
    }
  },
  "11155111": (response) => {
    if (response && response.result) {
      return response.result
    } else {
      return []
    }
  },
  "51": (response) => {
    if (response && response.items) {
      return response.items
    } else {
      return []
    }
  },
  "50": (response) => {
    if (response && response.items) {
      return response.items
    } else {
      return []
    }
  },
  "137": (response) => {
    if (response && response.result) {
      return response.result
    } else {
      return []
    }
  },
  "80002": (response) => {
    if (response && response.data && response.data.length > 0) {
      return response.data[0].transactionLists
    } else {
      return []
    }
  },
  "245022934": (response) => {
    if (response && response.data && response.data.items) {
      return response.data.items
    } else {
      return []
    }
  },
  "245022926": (response) => {
    if (response && response.data && response.data.items) {
      return response.data.items
    } else {
      return []
    }
  },
  "39": (response) => {
    if (response && response.items) {
      return response.items
    } else {
      return []
    }
  },
  "2484": (response) => {
    if (response && response.result) {
      return response.result
    } else {
      return []
    }
  },
  "1329": (response) => {
    if (response && response.items) {
      return response.items
    } else {
      return []
    }
  },
  "1328": (response) => {
    if (response && response.items) {
      return response.items
    } else {
      return []
    }
  },

}

export const RPC_URL_BY_CHAIN = {
  "1": "https://rpc.ankr.com/eth",
  "5": "https://rpc.ankr.com/eth_goerli",
  "11155111": "https://rpc2.sepolia.org",
  "51": "https://erpc.apothem.network",
  "50": "https://erpc.xinfin.network",
  "137": "https://polygon-rpc.com",
  "80002": "https://rpc-amoy.polygon.technology",
  "245022934": "https://neon-mainnet.everstake.one",
  "245022926": "https://devnet.neonevm.org",
  "39": "https://rpc-mainnet.u2u.xyz",
  "2484": "https://rpc-nebulas-testnet.uniultra.xyz",
  "1329": "https://sei.drpc.org",
  "1328": "https://evm-rpc-testnet.sei-apis.com"
}


export const NETWORKS = {
  "1": "Ethereum",
  "50": "XDC",
  "137": "Polygon",
}




export const uploadFileServer = async (data) => {

  try {

    var response = await axios.post(`/upload`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}



export const addMilesSeparator = (n) => {
  n = Math.round(n * 100000) / 100000;
  const parts = n.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const end = numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  return end;
}




export const theme = {
  backgroundColor: "#FFF",
  textColor: "#F8F8F8",
  primaryTextColor: "#F5E8CF",
  buttonTextColor: "#fff",
  primaryColor: '#10bf80',
  secondaryColor: '#1D1C1E',
  borderColor: "#D2D2D2",
  placeholderColor: "#C6C6C6",
  disabledColor: "#cccccc",
  themeBg: "#E3B155",
  white: "#fff",
  lightBlue: "#E5E9F2",
  statusColor: "#023020",
  errorColor: "#ff3333",
  websitecolor: "#0F1932",
  blackColor: "#000000",
  fieldBackground: "#F5E8CF",
  circleColor: "#F5E8CF",
  buyTextColor: "#707070"
}

Report.init({
  className: 'notiflix-report',
  width: '320px',
  backgroundColor: theme.secondaryColor,
  borderRadius: '25px',
  rtl: false,
  zindex: 4002,
  backOverlay: true,
  backOverlayColor: 'rgba(0,0,0,0.5)',
  backOverlayClickToClose: false,
  fontFamily: 'Poppins',
  svgSize: '70px',
  plainText: true,
  titleFontSize: '16px',
  titleMaxLength: 34,
  messageFontSize: '13px',
  messageMaxLength: 400,
  buttonFontSize: '12px',
  buttonMaxLength: 34,
  cssAnimation: true,
  cssAnimationDuration: 360,
  cssAnimationStyle: 'fade',
  success: {
    svgColor: theme.primaryColor,
    titleColor: theme.primaryTextColor,
    messageColor: theme.primaryTextColor,
    buttonBackground: theme.primaryColor,
    buttonColor: '#fff',
    backOverlayColor: 'rgba(50,198,130,0.2)',
  },
  failure: {
    svgColor: theme.primaryColor,
    titleColor: theme.primaryTextColor,
    messageColor: theme.primaryTextColor,
    buttonBackground: theme.primaryColor,
    buttonColor: '#fff',
    backOverlayColor: 'rgba(255,85,73,0.2)',
  },
  warning: {
    svgColor: theme.primaryColor,
    titleColor: theme.primaryTextColor,
    messageColor: theme.primaryTextColor,
    buttonBackground: theme.primaryColor,
    buttonColor: '#fff',
    backOverlayColor: 'rgba(238,191,49,0.2)',
  },
  info: {
    svgColor: theme.primaryColor,
    titleColor: theme.primaryTextColor,
    messageColor: theme.primaryTextColor,
    buttonBackground: theme.primaryColor,
    buttonColor: '#fff',
    backOverlayColor: 'rgba(38,192,211,0.2)',
  },
});

export const ToastPopup = Notify;
export const ModalPopup = Report;
export const BlockPopup = Block;


export const Arrow_SVG = <>
  {/*?xml version="1.0" encoding="UTF-8"?*/}
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {/* Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch */}
    <title>Stockholm-icons / Navigation / Arrow-right</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Stockholm-icons-/-Navigation-/-Arrow-right"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
      <rect
        id="Rectangle"
        fill="#335EEA"
        opacity="0.3"
        transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
        x={11}
        y={5}
        width={2}
        height={14}
        rx={1}
      />
      <path
        d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
        id="Path-94"
        fill="#335EEA"
        transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "
      />
    </g>
  </svg>
</>

const defaultChain = localStorage.chainId || 2484
export const customNodeOptions = {
  rpcUrl: RPC_URL_BY_CHAIN[defaultChain], // Custom RPC URL
  chainId: parseInt(defaultChain), // Custom chain id
}

